import { Button, Grid, Modal } from "@geist-ui/react";
import { useAvailableWeb3React } from "./hooks";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CONFIGS } from "./environment";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectors";

const availableChains = Object.entries(CONFIGS).map(
  ([chainId, chainConfig]) => {
    const hexChainId = `0x${parseInt(chainId, 10).toString(16)}`;
    return [hexChainId, chainConfig.name];
  }
);
interface ChainPickerProps {
  visible: boolean;
  onClose: () => void;
  onChainChange?: (fg: string, bg: string) => void;
}

async function switchChain(chainId: string) {
  const w = window as any;
  if (w.ethereum && w.ethereum.request) {
    await w.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  }
}

const ChainPicker: FunctionComponent<ChainPickerProps> = (
  props: ChainPickerProps
) => {
  const web3React = useWeb3React();

  const onChainSelect = async (chainId: string) => {
    if (!web3React.active) {
      await web3React.activate(injected);
    }
    switchChain(chainId);
    props.onClose();
  };

  return (
    <div className="chainPicker">
      <Modal visible={props.visible} onClose={props.onClose}>
        <Modal.Title>Select Network</Modal.Title>
        <Modal.Content>
          <Grid.Container gap={1} justify="space-evenly">
            {availableChains.map(([chainId, name]) => {
              return (
                <Grid sm={12} key={chainId} justify="space-evenly">
                  <Button
                    auto
                    key={chainId}
                    onClick={() => onChainSelect(chainId)}
                  >
                    {name}
                  </Button>
                </Grid>
              );
            })}
          </Grid.Container>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ChainPicker;
