import { BigNumber, ethers } from "ethers";
import PX256Artifact from "./artifacts/contracts/PX256.sol/PX256.json";
import { PX256 } from "./typechain/PX256";
import { configForChainId } from "./environment";

export function scannerHost(chainId: number) {
  const config = configForChainId(chainId);
  return config && config.scannerHostname;
}

export function scannerAddressURL(
  address: string,
  chainId: number,
  kind: string = "address"
) {
  let host = scannerHost(chainId);
  if (host) {
    return `https://${host}/${kind}/${address}`;
  }
}

export function shortenAddress(address: string) {
  let s1 = address.substr(0, 8);
  let s2 = address.substr(address.length - 4, 4);
  return `${s1}...${s2}`;
}

export function contractAddress(chainId: number) {
  return configForChainId(chainId).contractAddress;
}

export function contract(
  library: ethers.providers.Web3Provider,
  chainId: number
) {
  const address = contractAddress(chainId)!;
  return new ethers.Contract(address, PX256Artifact.abi, library) as PX256;
}

export function createOpenSeaURL(chainId: number, tokenId: BigNumber) {
  const config = configForChainId(chainId);
  const address = contractAddress(chainId);
  if (config.openSeaURL) {
    return `${config.openSeaURL}${address}/${tokenId.toString()}`;
  }
}
