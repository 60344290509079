import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { CONFIGS, DEFAULT_CHAIN_ID } from "./environment";
// import { LedgerConnector } from "@web3-react/ledger-connector";
// import { TrezorConnector } from "@web3-react/trezor-connector";
// import { FrameConnector } from "@web3-react/frame-connector";
// import { FortmaticConnector } from "@web3-react/fortmatic-connector";
// import { PortisConnector } from "@web3-react/portis-connector";
// import { SquarelinkConnector } from "@web3-react/squarelink-connector";
// import { TorusConnector } from "@web3-react/torus-connector";
// import { AuthereumConnector } from "@web3-react/authereum-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = (() => {
  let urls: { [chainId: number]: string } = {};
  Object.keys(CONFIGS).forEach((chainId) => {
    urls[parseInt(chainId, 10)] = CONFIGS[chainId].rpcURL;
  });
  return urls;
})();

export const injected = (() => {
  const chainIds = Object.keys(CONFIGS).map((x) => parseInt(x, 10));
  return new InjectedConnector({
    supportedChainIds: chainIds,
  });
})();

export const network = (() => {
  return new NetworkConnector({
    urls: RPC_URLS,
    defaultChainId: parseInt(DEFAULT_CHAIN_ID as string, 10),
  });
})();

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: "256px",
});

// export const ledger = new LedgerConnector({
//   chainId: 1,
//   url: RPC_URLS[1],
//   pollingInterval: POLLING_INTERVAL,
// });

// export const trezor = new TrezorConnector({
//   chainId: 1,
//   url: RPC_URLS[1],
//   pollingInterval: POLLING_INTERVAL,
//   manifestEmail: "dummy@abc.xyz",
//   manifestAppUrl: "https://8rg3h.csb.app/",
// });

// export const frame = new FrameConnector({ supportedChainIds: [1] });

// export const fortmatic = new FortmaticConnector({
//   apiKey: "pk_live_F95FEECB1BE324B5",
//   chainId: 1,
// });

// export const portis = new PortisConnector({
//   dAppId: "211b48db-e8cc-4b68-82ad-bf781727ea9e",
//   networks: [1, 100],
// });

// export const squarelink = new SquarelinkConnector({
//   clientId: "5f2a2233db82b06b24f9",
//   networks: [1, 100],
// });

// export const torus = new TorusConnector({ chainId: 1 });

// export const authereum = new AuthereumConnector({ chainId: 42 });
