import { useCallback, useEffect, useRef, useState } from "react";

interface IEditorProps {
  pixels: Array<boolean>;
  width: number;
  onChange?: (pixels: Array<boolean>) => void;
  viewOnly?: boolean;
  onClick?: (pixels: Array<boolean>) => void;
  onMouseOver?: () => void;
  bgColor: string;
  fgColor: string;
  onDataURLChange?: (dataURL: string) => void;
}

function EditorCanvas(props: IEditorProps) {
  const [mouseDown, setMouseDown] = useState(false);
  const [currentColor, setCurrentColor] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas: HTMLCanvasElement = canvasRef.current!;
    const context: CanvasRenderingContext2D = canvas.getContext("2d")!;
    const pxSide = props.width / 16;

    context.fillStyle = props.bgColor;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    let x, y;
    props.pixels.forEach((p, i) => {
      if (p) {
        x = i % 16;
        y = Math.floor(i / 16);

        context.beginPath();
        context.fillStyle = props.fgColor;
        context.fillRect(x * pxSide, y * pxSide, pxSide, pxSide);
      }
    });
  }, [props.pixels, props.width, props.bgColor, props.fgColor]);

  useEffect(() => {
    if (props.onDataURLChange && canvasRef.current) {
      let dataURL = canvasRef.current.toDataURL("image/png");
      props.onDataURLChange(dataURL);
    }
  }, [props, props.pixels]);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!props.viewOnly && mouseDown) {
        let [x, y] = mousePixelPosition(e, canvasRef.current!, 16);
        if (x >= 0 && x < 16 && y >= 0 && y < 16) {
          const newPixels = [...props.pixels];
          newPixels[y * 16 + x] = currentColor;
          props.onChange!(newPixels);
        }
      }
    },
    [props, currentColor, mouseDown]
  );

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (e.target !== canvasRef.current) {
        return false;
      }
      setMouseDown(true);
      let [x, y] = mousePixelPosition(e, canvasRef.current!, 16);
      if (!props.viewOnly && x >= 0 && x < 16 && y >= 0 && y < 16) {
        const newColor = !props.pixels[y * 16 + x];
        setCurrentColor(newColor);
        const newPixels = [...props.pixels];
        newPixels[y * 16 + x] = newColor;
        props.onChange!(newPixels);
      }
    },
    [props]
  );

  const onMouseUp = useCallback((e: MouseEvent) => {
    setMouseDown(false);
  }, []);

  useEffect(() => {
    if (!props.viewOnly) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mousedown", onMouseDown);
      window.addEventListener("mouseup", onMouseUp);
      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mousedown", onMouseDown);
        window.removeEventListener("mouseup", onMouseUp);
      };
    }
  }, [props.viewOnly, onMouseDown, onMouseMove, onMouseUp]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.pixels);
    }
  };

  return (
    <div
      className="EditorCanvas"
      style={{ maxWidth: props.width + 4, maxHeight: props.width + 4 }}
    >
      <canvas
        ref={canvasRef}
        width={props.width}
        height={props.width}
        onClick={onClick}
        onMouseOver={props.onMouseOver}
      ></canvas>
    </div>
  );
}

export default EditorCanvas;

//// Helpers

function mousePixelPosition(
  ev: MouseEvent,
  el: HTMLElement,
  numPixels: number
) {
  let rect = el.getBoundingClientRect();
  let x = ev.clientX - rect.left; //x position within the element.
  let y = ev.clientY - rect.top; //y position within the element.

  let pxX = Math.floor((numPixels * x) / rect.width);
  let pxY = Math.floor((numPixels * y) / rect.height);

  return [pxX, pxY];
}
