import { BigNumber } from "@ethersproject/bignumber";
import { Button, Tooltip } from "@geist-ui/react";
import * as Icons from "@geist-ui/react-icons";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { FunctionComponent } from "react";
import { injected } from "./connectors";

interface MintButtonProps {
  mint: () => void;
  mintPrice?: BigNumber;
}

const MintButton: FunctionComponent<MintButtonProps> = (
  props: MintButtonProps
) => {
  const web3React = useWeb3React();
  const connect = () => {
    web3React.activate(injected);
  };
  const mintDisplayPrice =
    props.mintPrice && ethers.utils.formatUnits(props.mintPrice);

  return (
    <div className="mintBox">
      {web3React.active ? (
        <Tooltip
          text={
            mintDisplayPrice
              ? `Transaction gas + fee of ${mintDisplayPrice} eth`
              : "Calculating price..."
          }
          placement="bottom"
        >
          <Button
            icon={<Icons.Save />}
            type="success"
            scale={2 / 3}
            auto
            onClick={props.mint}
          >
            Mint
          </Button>
        </Tooltip>
      ) : (
        <Button scale={1 / 2} auto onClick={connect}>
          Connect Wallet
        </Button>
      )}
    </div>
  );
};

export default MintButton;
