// Returns undefined if invalid

import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

// Returns the valid string (case-corrected) if valid
export function max(a: BigNumberish, b: BigNumberish): BigNumberish {
  if (BigNumber.from(a).gt(b)) {
    return a;
  } else {
    return b;
  }
}
// Returns the valid string (case-corrected) if valid
export function min(a: BigNumberish, b: BigNumberish): BigNumberish {
  if (BigNumber.from(a).lt(b)) {
    return a;
  } else {
    return b;
  }
}
