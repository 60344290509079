import OwnerPage from "./OwnerPage";
import EditorPage from "./EditorPage";
import ShowPage from "./ShowPage";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid, Spacer } from "@geist-ui/react";

import WalletButton from "./WalletButton";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="nav">
          <Grid.Container>
            <Grid xs={4}></Grid>
            <Grid xs={16} justify="space-evenly">
              <div className="title">
                <h1>uint256</h1>
                <p>Draw and mint unique 16x16 tokens</p>
              </div>
            </Grid>
            <Grid xs={4} justify="flex-end">
              <WalletButton />
            </Grid>
          </Grid.Container>
        </div>
        <Switch>
          <Route path="/owner" component={OwnerPage} exact />
          <Route path="/details/:hex" component={ShowPage} exact />
          <Route path="/:hex?" component={EditorPage} />
        </Switch>
      </div>
      <Spacer h={5} />
      {/* Created by <Link href="https://brentfitzgerald.com/">Brent</Link>. */}
    </Router>
  );
}

export default App;
