import assert from "assert";

export const SITE_TITLE = "uint256.art";

export const DEFAULT_CHAIN_ID = process.env.REACT_APP_DEFAULT_CHAIN_ID;

assert(
  process.env.REACT_APP_DEFAULT_CHAIN_ID,
  "process.env.REACT_APP_DEFAULT_CHAIN_ID not set"
);

interface EnvConfig {
  name: string;
  contractAddress: string;
  rpcURL: string;
  scannerHostname?: string;
  openSeaURL?: string;
}

export const CONFIGS: { [chainId: string]: EnvConfig } = {};

function register(chainId: string, config: EnvConfig) {
  if (config.contractAddress) {
    CONFIGS[chainId] = config;
  }
}

register("1", {
  name: "Mainnet",
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_MAINNET!,
  rpcURL: "https://mainnet.infura.io/v3/4b36b01b3d764d418b0e7580eb178fd4",
  scannerHostname: "etherscan.io",
});

register("4", {
  name: "Rinkeby",
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_RINKEBY!,
  rpcURL:
    "https://eth-rinkeby.alchemyapi.io/v2/UQGjXLxkv5Xp7QdaG6-ZWot_Lyoj4eyS",
  scannerHostname: "rinkeby.etherscan.io",
  openSeaURL: "https://testnets.opensea.io/assets/",
});

register("1337", {
  name: "Localhost",
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_LOCALHOST!,
  rpcURL: "http://127.0.0.1:8545/",
});

register("137", {
  name: "Polygon",
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_POLYGON!,
  rpcURL:
    "https://rpc-mainnet.maticvigil.com/v1/8780457cde800c2a83260aba94dc825d84b1a7ca",
  scannerHostname: "polygonscan.com",
});

register("80001", {
  name: "Polygon - Mumbai Testnet",
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_MUMBAI!,
  rpcURL:
    "https://rpc-mumbai.maticvigil.com/v1/8780457cde800c2a83260aba94dc825d84b1a7ca",
  scannerHostname: "mumbai.polygonscan.com",
});

assert(
  Object.keys(CONFIGS).length > 0,
  "At least one contract needs to be defined"
);

export function configForChainId(chainId: number): EnvConfig {
  const config = CONFIGS[chainId.toString(10)];
  assert(config, `Chain ID ${chainId} does not have a configuration`);
  return config;
}
