import { parseBytes32String } from "@ethersproject/strings";
import { Grid, Spacer } from "@geist-ui/react";
import { BigNumber } from "ethers";
import { FunctionComponent, useEffect, useState } from "react";
import * as BNUtil from "./bignumber-utils";
import { contract } from "./contract-helpers";
import GalleryItem, { GalleryItemProps } from "./GalleryItem";
import { useAvailableWeb3React } from "./hooks";

const DEFAULT_PAGINATION_LIMIT = 100;

interface GalleryProps {
  onSelect: (pixels: Array<boolean>) => void;
}

const Gallery: FunctionComponent<GalleryProps> = (props: GalleryProps) => {
  // const web3React = useWeb3React();
  const web3React = useAvailableWeb3React();
  const [tokenSupply, setTokenSupply] = useState(BigNumber.from(0));
  const [galleryNeedsRefresh, setGalleryNeedsRefresh] =
    useState<boolean>(false);
  const [items, setItems] = useState<GalleryItemProps[]>([]);

  useEffect(() => {
    let listener: (from: string, to: string, tokenId: BigNumber) => void;
    if (web3React.active && web3React.chainId) {
      let c = contract(web3React.library, web3React.chainId);
      listener = (from: string, to: string, tokenId: BigNumber) => {
        setGalleryNeedsRefresh(true);
      };
      c.on("Transfer", listener);

      setGalleryNeedsRefresh(true);
    }
    return () => {
      if (web3React && web3React.chainId) {
        let c = contract(web3React.library, web3React.chainId);
        c.removeListener("Transfer", listener);
      }
    };
  }, [web3React]);

  useEffect(() => {
    if (web3React.active && web3React.chainId && galleryNeedsRefresh) {
      (async () => {
        if (!web3React.active || !web3React.chainId) {
          console.error("No active web3 connection. Unable to fetch.");
          return;
        }
        try {
          let s = await contract(
            web3React.library,
            web3React.chainId
          ).totalSupply();

          setTokenSupply(s);

          let index = BNUtil.max(s.sub(DEFAULT_PAGINATION_LIMIT), 0);
          let results = await contract(
            web3React.library,
            web3React.chainId
          ).list(index, s);

          const newItems = results.map((item): GalleryItemProps => {
            return {
              tokenId: item.tokenId,
              owner: item.owner,
              title: parseBytes32String(item.title),
            };
          });

          let rev = [...newItems];
          rev.reverse();
          setItems(rev);
        } catch (e: any) {
          console.error(e);
        }
      })();

      setGalleryNeedsRefresh(false);
    }
  }, [galleryNeedsRefresh, web3React]);

  const galleryItems = items.map((item, index) => (
    <Grid key={item.tokenId.toHexString()}>
      <GalleryItem onClick={props.onSelect} {...item} />
    </Grid>
  ));

  return (
    <div className="thumbnails">
      <Grid.Container justify="center" gap={2}>
        <Grid>
          <div>
            <strong> {tokenSupply.toString()} mints</strong>
          </div>
          <Spacer h={1} />
        </Grid>
      </Grid.Container>
      <Grid.Container justify="flex-start" gap={1}>
        {galleryItems}
      </Grid.Container>
      <Spacer h={2} />
    </div>
  );
};

export default Gallery;
