import {
  Grid,
  Input,
  Link,
  Spacer,
  Tooltip,
  useMediaQuery,
  useToasts,
} from "@geist-ui/react";
import * as Icons from "@geist-ui/react-icons";
import { BigNumber, ethers } from "ethers";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { contract, createOpenSeaURL, shortenAddress } from "./contract-helpers";
import EditorCanvas from "./EditorCanvas";
import { SITE_TITLE } from "./environment";
import { encodeHex } from "./hexutil";
import { useAvailableWeb3React } from "./hooks";
import MintButton from "./MintButton";
import OnChainAddress from "./ChainAddressLink";
import { Helmet } from "react-helmet-async";
import { formatBytes32String, parseBytes32String } from "ethers/lib/utils";
import { Item } from "./models/item";

const SAVE_DELAY = 400;
const TOAST_DELAY = 3000;

interface EditorProps {
  pixels?: Array<boolean>;
  onChange?: (pixels: Array<boolean>, title?: string, owner?: string) => void;
}

const fgColor = "#000000";
const bgColor = "#ffffff";

function Editor(props: EditorProps) {
  const [localPixels, setLocalPixels] = useState(
    props.pixels || Array(256).fill(false)
  );
  const [owner, setOwner] = useState<string>();
  const [toasts, setToast] = useToasts();
  const [title, setTitle] = useState<string>();
  const [dataURL, setDataURL] = useState<string>();
  const upXS = useMediaQuery("xs", { match: "down" });
  const [mintPrice, setMintPrice] = useState<BigNumber>();
  let web3React = useAvailableWeb3React();

  const pixels = props.pixels || Array(256).fill(false);

  const updateOwnership = useCallback(async () => {
    if (web3React.library && web3React.chainId) {
      const hex = `${encodeHex(pixels)}`;
      try {
        let t = await contract(web3React.library, web3React.chainId).get(
          BigNumber.from(hex)
        );
        setOwner(t.owner);
        setTitle(parseBytes32String(t.title));
      } catch (e: any) {
        setOwner(undefined);
        setTitle(undefined);
        if (e.data?.message?.match(/nonexistent token/)) {
          console.info("Nonexistent token 'error' returned.");
        } else {
          console.warn("JSON-RPC ownership request returned unknown error", e);
        }
      }
    }
  }, [web3React.library, web3React.chainId, pixels]);

  useEffect(() => {
    setLocalPixels(pixels);
  }, [pixels]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      updateOwnership();
      if (props.onChange) {
        props.onChange!(localPixels);
      }
    }, SAVE_DELAY);
    return () => clearTimeout(timeOutId);
  }, [localPixels, props.onChange, updateOwnership]);

  const onMint = useCallback(async () => {
    if (web3React.active && web3React.chainId && mintPrice) {
      const hex = `${encodeHex(pixels)}`;
      const c = contract(web3React.library, web3React.chainId);
      const signer = c.connect(web3React.library.getSigner());
      const n = ethers.BigNumber.from(hex);
      try {
        const response = await signer.publicMint(
          [
            {
              tokenId: n,
              title: formatBytes32String(title || ""),
            },
          ],
          {
            value: mintPrice,
          }
        );
        setToast({
          text: `Transaction initiated`,
        });

        const receipt = await response.wait();
        setToast({
          text: `Transaction confirmed: ${receipt.transactionHash}`,
          type: "success",
          delay: TOAST_DELAY,
        });
        setOwner(signer.address);
      } catch (e: any) {
        setToast({
          type: "warning",
          text: e.message!,
          delay: TOAST_DELAY,
        });
      }
    }
  }, [
    web3React.active,
    web3React.chainId,
    web3React.library,
    mintPrice,
    pixels,
    title,
    setToast,
  ]);

  useEffect(() => {
    if (!web3React.active) {
      setOwner(undefined);
      setTitle(undefined);
    }
  }, [web3React.active]);

  useEffect(() => {
    (async () => {
      if (web3React.active && web3React.library) {
        const c = contract(web3React.library, web3React.chainId!);
        let currentMintPrice = await c.mintPrice();
        setMintPrice(currentMintPrice);
      }
    })();
  }, [web3React]);

  const clearEditor = () => {
    const newPixels = Array(256).fill(false);
    setLocalPixels(newPixels);
  };

  const invertEditor = () => {
    const newPixels = localPixels.map((p) => !p);
    setLocalPixels(newPixels);
  };

  const onChange = (newPixels: Array<boolean>) => {
    setLocalPixels(newPixels);
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onDataURLChange = (dataURL: string) => {
    setDataURL(dataURL);
  };

  let titleParts = [SITE_TITLE];
  if (owner) {
    titleParts.unshift(shortenAddress(owner));
  }
  if (title) {
    titleParts.unshift(title);
  }

  let openSeaURL =
    web3React.chainId &&
    createOpenSeaURL(web3React.chainId, Item.fromPixels(pixels).tokenId);

  return (
    <div className="Editor">
      <Helmet>
        <title>{titleParts.join(" - ")}</title>
        <meta name="description" content="Draft pixel art" />
        <link rel="icon" type="image/png" href={dataURL} />
      </Helmet>

      <Grid.Container
        gap={0.5}
        justify="center"
        alignContent="space-between"
        alignItems="flex-start"
      >
        <Grid sm />
        <Grid xs={0} sm={2}></Grid>
        <Grid xs={24} sm={16} md={10} justify="space-evenly">
          <div className="mainEditor">
            <div className={`previewPane ${upXS && "hidden"}`}>
              <EditorCanvas
                width={48}
                pixels={localPixels}
                fgColor={fgColor}
                bgColor={bgColor}
                viewOnly
              />
              <EditorCanvas
                width={16}
                pixels={localPixels}
                fgColor={fgColor}
                bgColor={bgColor}
                viewOnly
                onDataURLChange={onDataURLChange}
              />
            </div>
            <EditorCanvas
              width={416}
              pixels={localPixels}
              onChange={onChange}
              fgColor={fgColor}
              bgColor={bgColor}
            />
            <Spacer h={0.2} />
            <Grid.Container gap={1}>
              <Grid>
                <Tooltip text="XOR all values" placement="bottom">
                  <div className="button" onClick={invertEditor}>
                    <Icons.Shuffle size={20} />
                  </div>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip text="Clear and start over" placement="bottom">
                  <div className="button" onClick={clearEditor}>
                    <Icons.Trash2 size={20} />
                  </div>
                </Tooltip>
              </Grid>
              <Grid xs />

              <Grid alignContent="flex-end">
                <div className="ownership">
                  {web3React.active && (
                    <>
                      {!owner && (
                        <div>
                          <Input
                            scale={2 / 3}
                            placeholder="Untitled"
                            style={{ textAlign: "right" }}
                            onChange={onTitleChange}
                            maxLength={31}
                          />
                        </div>
                      )}

                      <div>
                        {owner && <strong>{title ? title : "Untitled"}</strong>}
                      </div>
                      <div>
                        {owner && owner === web3React.account && (
                          <span>You own this</span>
                        )}
                        {owner && owner !== web3React.account && (
                          <span>
                            Owned by <OnChainAddress account={owner} />
                          </span>
                        )}
                      </div>

                      {owner && openSeaURL && (
                        <div>
                          <Link color href={openSeaURL} className="openseaLink">
                            OpenSea
                            <span className="openseaLinkImg"></span>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                  {!owner && (
                    <div>
                      <Spacer h={0.5} />

                      <MintButton mint={onMint} mintPrice={mintPrice} />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid.Container>
          </div>
        </Grid>

        <Grid xs={0} sm={2}>
          {" "}
        </Grid>
        <Grid sm />
      </Grid.Container>

      <div className="editorPanes"></div>
    </div>
  );
}

export default Editor;
