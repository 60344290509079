// Returns undefined if invalid

// Returns the valid string (case-corrected) if valid
export function cleanHexVal(hex: string): string | void {
  if (!hex) {
    return;
  }
  if (hex.startsWith("0x")) {
    hex = hex.substr(2);
  }
  let m = hex.match(/^[0-9A-F]+$/i);
  if (m) {
    let result = m[0].toUpperCase();
    if (result.length > 64) {
      return `0x${result.substring(0, 64)}`;
    } else {
      return `0x${result.padStart(64, "0")}`;
    }
  }
}

export function decodeHex(hex: string): Array<boolean> {
  if (hex.startsWith("0x")) {
    hex = hex.substr(2);
  }
  let ret: Array<boolean> = [];
  for (let i = 0; i < hex.length; i++) {
    let nibble = parseInt(hex[i], 16);
    for (let j = 0; j < 4; j++) {
      let value = (nibble & (0x1 << (3 - j))) !== 0;
      ret.push(value);
    }
  }
  return ret;
}

export function encodeHex(pixels: Array<boolean>): string {
  let ret = "";
  for (let i = 0; i < pixels.length; i += 4) {
    let nibbleValue = 0;
    for (let j = 0; j < 4; j++) {
      if (pixels[i + j]) {
        nibbleValue |= 0x1 << (3 - j);
      }
    }
    ret += nibbleValue.toString(16);
  }
  return `0x${ret.toUpperCase()}`;
}
