import { Web3Provider } from "@ethersproject/providers";
import { CssBaseline, GeistProvider, Themes } from "@geist-ui/react";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "./Main";

function getLibrary(provider: any): Web3Provider {
  const lib = new Web3Provider(provider);
  lib.pollingInterval = 5000;
  return lib;
}

const Web3ReactRPCProvider = createWeb3ReactRoot("network");

const myTheme = Themes.createFromLight({
  type: "Custom",
  palette: {
    // success: "#000",
  },
  font: {
    sans: "Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace",
    mono: "Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace",
  },
});

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>uint256.art</title>
        <link rel="canonical" href="https://uint256.art/" />
      </Helmet>{" "}
      <GeistProvider themes={[myTheme]} themeType="Custom">
        <CssBaseline />
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ReactRPCProvider getLibrary={getLibrary}>
            <Main />
          </Web3ReactRPCProvider>
        </Web3ReactProvider>
      </GeistProvider>
    </HelmetProvider>
  );
}

export default App;
