import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { contract } from "./contract-helpers";
import { cleanHexVal } from "./hexutil";
import {
  parseJSONDataURL,
  decodeJSONBase64DataURL,
} from "./json-data-url-helper";

interface ShowPageParams {
  hex: string;
}
interface ShowPageProps extends RouteComponentProps<ShowPageParams> {}

function ShowPage({ match }: ShowPageProps) {
  let { hex } = match.params;
  const web3React = useWeb3React();
  const [message, setMessage] = useState<string>();
  const [metadataJSON, setMetadataJSON] = useState<string>();
  const [svg, setSVG] = useState<string>();

  if (!hex) {
    hex = "0x00";
  }
  useEffect(() => {
    if (web3React.active && web3React.chainId) {
      const c = contract(web3React.library, web3React.chainId);
      (async () => {
        try {
          let metadataURL = await c.tokenURI(BigNumber.from(hex));
          setMetadataJSON(decodeJSONBase64DataURL(metadataURL));
          let metadata = parseJSONDataURL(metadataURL);
          let svg = metadata["image"];
          setSVG(svg);
        } catch (e) {
          setMessage(`${e}`);
        }
      })();
    }
  }, [web3React, hex]);

  let validHexVal = cleanHexVal(hex);
  if (!validHexVal) {
    return <Redirect to="" />;
  } else if (hex !== validHexVal) {
    return <Redirect to={validHexVal} />;
  }

  if (web3React.active) {
    return (
      <div className="ShowPage">
        <p>{message}</p>
        <img src={svg} alt={`svg for ${hex}`} />
        <pre>{svg}</pre>
        <pre>{metadataJSON}</pre>
      </div>
    );
  } else {
    return <p>Please connect your wallet to view</p>;
  }
}

export default ShowPage;
