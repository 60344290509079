import { useWeb3React } from "@web3-react/core";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Grid, Page, Spinner, Input } from "@geist-ui/react";
import { ArrowDown, Save } from "@geist-ui/react-icons";
import { contract, contractAddress } from "./contract-helpers";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { formatBytes32String } from "@ethersproject/strings";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";

interface OwnerParams {}
interface OwnerPageProps extends RouteComponentProps<OwnerParams> {}

function Owner({ match }: OwnerPageProps) {
  const web3React = useWeb3React();
  const networkWeb3react = useWeb3React("network");

  const [description, setDescription] = useState<string>("");
  const [url, setURL] = useState<string>("");
  const [balance, setBalance] = useState<BigNumber>();

  const contractWithSigner = (w: Web3ReactContextInterface) => {
    const c = contract(w.library!, w.chainId!);
    return c.connect(w.library.getSigner());
  };

  const withdraw = async () => {
    if (!web3React.active || !web3React.library || !web3React.chainId) {
      alert("Please connect wallet");
      return;
    }
    await contractWithSigner(web3React).withdraw();
  };

  useEffect(() => {
    (async () => {
      if (networkWeb3react.active && networkWeb3react.chainId) {
        const balance = await networkWeb3react.library.getBalance(
          contractAddress(networkWeb3react.chainId)
        );
        setBalance(balance);
      }
    })();
  }, [networkWeb3react]);

  const saveURL = useCallback(async () => {
    if (web3React.library && web3React.chainId) {
      await contractWithSigner(web3React).setURL(formatBytes32String(url));
    }
  }, [url, web3React]);

  const saveDescription = useCallback(async () => {
    if (web3React.library && web3React.chainId) {
      await contractWithSigner(web3React).setDescription(
        formatBytes32String(description)
      );
    }
  }, [description, web3React]);

  return (
    <>
      <Page>
        <Page.Header>
          <h1>Admin ops</h1>
        </Page.Header>
        <Page.Content>
          <Grid.Container gap={2}>
            <Grid>
              <Card shadow>
                <h2>Contract Balance</h2>
                <div>
                  {balance ? `${formatEther(balance!)} eth` : <Spinner />}{" "}
                </div>
                <Button type="success" icon={<ArrowDown />} onClick={withdraw}>
                  Withdraw
                </Button>
              </Card>
            </Grid>
            <Grid>
              <Card shadow>
                <h2>URL</h2>
                <Input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setURL(e.target.value);
                  }}
                />
                <Button type="success" icon={<Save />} onClick={saveURL}>
                  Set URL
                </Button>
              </Card>
            </Grid>
            <Grid>
              <Card shadow>
                <h2> Description</h2>
                <Input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value);
                  }}
                />
                <Button
                  type="success"
                  icon={<Save />}
                  onClick={saveDescription}
                >
                  Set Description
                </Button>
              </Card>
            </Grid>
          </Grid.Container>
        </Page.Content>
      </Page>
    </>
  );
}

export default Owner;
