import { Modal } from "@geist-ui/react";
import ChainAddressLink from "./ChainAddressLink";
import { configForChainId } from "./environment";
import { useAvailableWeb3React } from "./hooks";

interface AboutModalProps {
  visible: boolean;
  onClose: () => void;
}

function AboutModal(props: AboutModalProps) {
  const { chainId } = useAvailableWeb3React();
  const config = chainId && configForChainId(chainId);
  const contractAddress = config && config.contractAddress;
  const chainAddressLink = contractAddress && (
    <ChainAddressLink
      account={contractAddress}
      kind="token"
      displayFull={true}
      etherscanLink={true}
    />
  );
  return (
    <>
      <Modal visible={props.visible} onClose={props.onClose}>
        <Modal.Title>About uint256.art</Modal.Title>
        <Modal.Content>
          <p>
            The <strong>uint256.art</strong> project lets you draw your own 1:1
            256-bit NFT as a 16x16 image.
          </p>
          <p>
            Your artwork is a unique number, possibly quite large! You can view
            the hexadecimal (base-16) version of your art in the URL.
          </p>
          <h2>Is my drawing actually unique?</h2>
          <p>
            Your drawing <strong>is</strong> the identifier of your NFT! Once
            you've minted your drawing, no one else can create that exact
            drawing using this contract.
          </p>
          <h2>Where is my drawing stored?</h2>
          <p>
            All data storage, metadata rendering, and SVG rendering are
            on-chain. The contract implements <code>ERC721Metadata</code>, so
            anything you draw here is a standard NFT viewable and tradable via a
            variety of platforms.
          </p>

          {contractAddress && (
            <>
              <h2>What is the contract address?</h2>
              <p>{chainAddressLink || contractAddress}</p>
            </>
          )}
        </Modal.Content>
        <Modal.Action passive onClick={props.onClose}>
          Cancel
        </Modal.Action>
        <Modal.Action>Submit</Modal.Action>
      </Modal>
    </>
  );
}

export default AboutModal;
