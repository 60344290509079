export function decodeJSONBase64DataURL(url: string): any {
  const regex = /^data:application\/json;base64,(.*)$/;

  const matches = url.match(regex);
  if (!matches) {
    return;
  }
  const data = matches[1];
  const buffer = Buffer.from(data, "base64");
  return buffer.toString();
}

export function parseJSONDataURL(url: string): any {
  const s = decodeJSONBase64DataURL(url);
  return JSON.parse(s);
}
