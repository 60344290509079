import { Grid, Spacer } from "@geist-ui/react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import Editor from "./Editor";
import Gallery from "./Gallery";
import { cleanHexVal } from "./hexutil";
import { Item } from "./models/item";

interface EditorPageParams {
  hex: string;
}
interface EditorProps extends RouteComponentProps<EditorPageParams> {}

function EditorPage({ match }: EditorProps) {
  let { hex } = match.params;
  let history = useHistory();
  let item: Item;
  if (!hex) {
    item = Item.blank();
  } else {
    let validHexVal = cleanHexVal(hex);
    if (!validHexVal) {
      return <Redirect to="" />;
    } else if (hex !== validHexVal) {
      return <Redirect to={validHexVal} />;
    }
    item = new Item(hex);
  }

  const onChange = async (pixels: Array<boolean>) => {
    const item = Item.fromPixels(pixels);
    if (hex !== item.fullHex() && !(!hex && item.tokenId.eq(0))) {
      history.push(`/${item.fullHex()}`);
    }
  };

  const onSelect = (pixels: Array<boolean>) => {
    onChange(pixels);
  };

  return (
    <div className="EditorPage">
      <Editor pixels={item.pixels()} onChange={onChange} />
      <Spacer h={4} />
      <Grid.Container>
        <Grid xs />
        <Grid>
          <Gallery onSelect={onSelect} />
        </Grid>
        <Grid xs />
      </Grid.Container>
    </div>
  );
}

export default EditorPage;
