import { BigNumber, BigNumberish } from "ethers";
import { decodeHex, encodeHex } from "../hexutil";

export class Item {
  readonly tokenId;

  constructor(_tokenId: BigNumberish) {
    this.tokenId = BigNumber.from(_tokenId);
  }

  fullHex() {
    return `0x${this.tokenId.toHexString().substr(2).padStart(64, "0")}`;
  }

  pixels() {
    // TODO - decode directly from BigNumber
    return decodeHex(this.fullHex());
  }

  decimal() {
    return this.tokenId.toString();
  }

  static blank() {
    return new Item(0);
  }

  static fromHex(hex: string) {
    if (!hex.startsWith("0x")) {
      hex = `0x${hex}`;
    }
    const tokenId = BigNumber.from(hex);
    return new Item(tokenId);
  }

  static fromPixels(pixels: boolean[]) {
    // TODO - encode directly to bignumber
    return this.fromHex(encodeHex(pixels));
  }
}
