import { ButtonDropdown } from "@geist-ui/react";
import { useWeb3React } from "@web3-react/core";
import { FunctionComponent, useEffect, useState } from "react";
import AboutModal from "./AboutModal";
import ChainPicker from "./ChainPicker";
import { network } from "./connectors";
import { shortenAddress } from "./contract-helpers";
import { CONFIGS } from "./environment";
import { useEagerConnect, useInactiveListener } from "./hooks";

interface WalletButtonProps {}

const WalletButton: FunctionComponent<WalletButtonProps> = () => {
  const web3React = useWeb3React();
  const { account, active, chainId } = web3React;
  const networkName =
    active && chainId ? CONFIGS[chainId].name || "unknown" : undefined;
  const [aboutModalVisible, setAboutModalVisible] = useState(false);
  const [chainPickerVisible, setChainPickerVisible] = useState(false);

  const disconnect = () => {
    web3React.deactivate();
  };

  const onAccountClick = () => {
    setChainPickerVisible(true);
  };

  const onModalClose = () => {
    setAboutModalVisible(false);
  };

  const onChainPickerClose = () => {
    setChainPickerVisible(false);
  };

  // handle logic to recognize the web3React.connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === web3React.connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, web3React.connector]);

  // eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const networkWeb3React = useWeb3React("network");
  useEffect(() => {
    if (!networkWeb3React.active) {
      networkWeb3React
        .activate(network)
        .then(() => {
          network
            .getChainId()
            .then((id) =>
              console.info(`activated network web3 for network ${id}`)
            );
        })
        .catch((e) => {
          console.error(`Unable to activate network connector: ${e}`);
        });
    }
  }, [networkWeb3React]);

  // Make sure network chain ID follows the user's
  useEffect(() => {
    if (web3React.chainId && web3React.chainId !== networkWeb3React.chainId) {
      network.changeChainId(web3React.chainId);
    }
  }, [networkWeb3React.chainId, web3React]);

  return (
    <div>
      <AboutModal onClose={onModalClose} visible={aboutModalVisible} />
      <ChainPicker onClose={onChainPickerClose} visible={chainPickerVisible} />
      <ButtonDropdown>
        <ButtonDropdown.Item main onClick={onAccountClick}>
          <div>{account ? shortenAddress(account) : "Connect"}</div>
          {active && chainId && <div>&nbsp;({networkName})</div>}
        </ButtonDropdown.Item>
        {active && (
          <ButtonDropdown.Item onClick={disconnect}>
            Disconnect
          </ButtonDropdown.Item>
        )}
        <ButtonDropdown.Item onClick={() => setAboutModalVisible(true)}>
          About this project
        </ButtonDropdown.Item>
      </ButtonDropdown>
    </div>
  );
};

export default WalletButton;
