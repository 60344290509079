import { Link } from "@geist-ui/react";
import { FunctionComponent } from "react";
import { useWeb3React } from "@web3-react/core";
import { scannerAddressURL, shortenAddress } from "./contract-helpers";

export interface ChainAddressLinkProps {
  account: string;
  displayFull?: boolean;
  etherscanLink?: boolean;
  kind?: string;
}

const ChainAddressLink: FunctionComponent<ChainAddressLinkProps> = (
  props: ChainAddressLinkProps
) => {
  let displayAccount = props.displayFull
    ? props.account
    : shortenAddress(props.account);

  const web3React = useWeb3React();
  const kind = props.kind || "address";
  const url =
    web3React.chainId &&
    scannerAddressURL(props.account, web3React.chainId, kind);
  if (url) {
    return (
      <Link href={url} icon>
        {displayAccount}
      </Link>
    );
  } else {
    return <span>{displayAccount}</span>;
  }
};

export default ChainAddressLink;
