import { BigNumber } from "@ethersproject/bignumber";
import { Tooltip } from "@geist-ui/react";
import { FunctionComponent } from "react";
import EditorCanvas from "./EditorCanvas";
import OnChainAddress from "./ChainAddressLink";
import { Item } from "./models/item";
import { useWeb3React } from "@web3-react/core";

export interface GalleryItemProps {
  tokenId: BigNumber;
  owner: string;
  title: string;
  onClick?: (pixels: Array<boolean>) => void;
}

const GalleryItem: FunctionComponent<GalleryItemProps> = (
  props: GalleryItemProps
) => {
  const web3React = useWeb3React();

  let item = new Item(props.tokenId);
  const ownedByUser = web3React.active && web3React.account === props.owner;

  return (
    <div className="galleryItem">
      <Tooltip
        text={
          <>
            <div>
              <strong>{props.title}</strong>
            </div>
            <div>
              {ownedByUser ? (
                <span>You own this!</span>
              ) : (
                <span>
                  Owned by{" "}
                  <strong>
                    <OnChainAddress account={props.owner} />
                  </strong>
                </span>
              )}
            </div>
          </>
        }
        placement="bottomStart"
      >
        <EditorCanvas
          pixels={item.pixels()}
          width={48}
          viewOnly
          bgColor="#ffffff"
          fgColor="#000000"
          onClick={props.onClick}
        />
      </Tooltip>
    </div>
  );
};

export default GalleryItem;
